.App {
  text-align: left;
}

@font-face {
  font-family: Switzer;
  src: url(fonts/Switzer-Regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: Switzer;
  src: url(fonts/Switzer-Bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: Switzer;
  src: url(fonts/Switzer-Light.woff);
  font-weight: 300;
}

body {
  @apply bg-white;
  font-family: Switzer, Helvetica, Arial, sans-serif;
}

.viewSearch__searchbar {
    @apply py-3;
}

.viewSearch__container {
    @apply flex flex-col;
}

.viewSearch__content {
    @apply w-full p-6 order-1;
}

.viewSearch__content-title {
    @apply font-bold text-3xl;
}

.viewSearch__map {
    @apply w-full order-2;
}

.viewSearch__mapbox {
    width: 100%;
    height: 300px;
}

/*
@screen md {
    .viewSearch__container {
        @apply flex;
    }

    .viewSearch__map {
        @apply w-5/12 order-2;
    }

    .viewSearch__content {
        @apply w-7/12 order-1 p-8;
    }

    .viewSearch__mapbox {
        @apply border-b-0 border-l border-gray-200 overflow-hidden;
    }
}
*/

@screen lg {
    .viewSearch__container {
        @apply flex flex-row;
    }

    .viewSearch__map {
        @apply block w-4/12 order-2;
    }

    .viewSearch__content {
        @apply w-8/12 order-1 p-8;
    }

    .viewSearch__mapbox {
        @apply border-b-0 border-l border-gray-200 overflow-hidden;
    }

    .viewSearch__mapbox {
        position: -webkit-sticky;
        position: sticky;
        top: 6rem;
        bottom: 0;
        width: 100%;
        height: calc(100vh - 6rem);
    }
}

@screen xl {
    .viewSearch__map {
        @apply block w-5/12 order-2;
    }

    .viewSearch__content {
        @apply w-7/12 order-1 p-8;
    }
}

@screen 2xl {
    .viewSearch__map {
        @apply block w-6/12 order-2;
    }

    .viewSearch__content {
        @apply w-6/12 order-1 p-8;
    }
}

.markerLabel {
    transform: translateX(-10%);
}

.markerIcon {
    width: 30px;
    height: 36px;
    cursor: pointer;
}

.grow {
    transition: all .2s ease-in-out;
    transform: scale(1.2);
}

.mapboxgl-marker:not(:hover) {
    z-index: 0 !important;
}

.mapboxgl-marker:hover {
    z-index: 1000;
}
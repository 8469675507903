/*
.tippy-box[data-theme~='tomato'] {
    background-color: tomato;
    color: yellow;
}

.tippy-box[data-theme~='tomato'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: tomato;
}
.tippy-box[data-theme~='tomato'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: tomato;
}
*/

.tippy-box[data-theme~='tippySavings'] {
    border: 1px solid #0f9649;
    background-color: #0f9649;
    color: #ffffff;
}

.tippy-box[data-theme~='tippySavings'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #0f9649;
}
.tippy-box[data-theme~='tippySavings'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #0f9649;
}

.tippy-box[data-theme~='tippySavings2'] {
    border: 2px solid #00AA6C;
    /* background-color: #f0fdf4; */
    background-color: #ffffff;
    color: #3f3f3f;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.tippy-box[data-theme~='tippySavings2'][data-placement^='top'] > .tippy-arrow::before {
    margin-bottom: -1px;
    border-top-color: #00AA6C;
}
.tippy-box[data-theme~='tippySavings2'][data-placement^='bottom'] > .tippy-arrow::before {
    margin-top: -1px;
    border-bottom-color: #00AA6C;
}


.tippy-box[data-theme~='tippyDiscount'] {
    border: 1px solid #af3a14;
    background-color: #af3a14;
    color: #ffffff;
}

.tippy-box[data-theme~='tippyDiscount'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #af3a14;
}
.tippy-box[data-theme~='tippyDiscount'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #af3a14;
}


.tippy-box[data-theme~='tippyDiscount2'] {
    border: 2px solid #af3a14;
    /* background-color: #fef2f2; */
    background-color: #ffffff;
    color: #3f3f3f;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.tippy-box[data-theme~='tippyDiscount2'][data-placement^='top'] > .tippy-arrow::before {
    margin-bottom: -1px;
    border-top-color: #af3a14;
}
.tippy-box[data-theme~='tippyDiscount2'][data-placement^='bottom'] > .tippy-arrow::before {
    margin-top: -1px;
    border-bottom-color: #af3a14;
}

.fill-tripadvisor {
    fill: #00AA6C;
}

.fill-star {
    fill: #dbc400;
}

input:checked + svg {
    display: block;
}
.header__logo {
    max-width: 160px;
}

@screen lg {
    .header__logo {
        max-width: 220px;
    }
}

@screen xl {
    .header__logo {
        max-width: 240px;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "mapbox-gl/dist/mapbox-gl.css";
@import "header.css";
@import "daypicker.css";
@import "svg.css";
@import "viewSearch.css";
@import "viewHotelDetails.css";
@import "searchbar.css";
@import "tippyThemes.css";
@import "table.css";

/* reset list style css for html textarea input e.g. offer description */
.resetList ul, .resetList ol {
    list-style: revert;
    margin: 0.4em 1.8em;
}

/* mobile */

.tr-class {
    @apply flex flex-col mb-4 sm:table-row
}

.th-class {
    @apply border p-3 first:rounded-t-lg last:rounded-b-lg sm:first:rounded-t-none sm:last:rounded-b-none
}

.td-class {
    @apply border p-2 first:rounded-t-lg last:rounded-b-lg sm:first:rounded-t-none sm:last:rounded-b-none
}
